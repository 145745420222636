<template>
  <nav-bar class="mainbar">
    <div slot="left" @click="tohome()">
      <img src="~assets/logo.svg" alt="">
    </div>
    <div slot="center" class="navbar" :style="{ 'display': this.$route.query.query ? 'none' : 'flex' }">
      <el-row type="flex">
        <el-col v-for="(item, index) in navItem" :key="index"
          :style="{ 'min-width': (index === 9 || index === 8 || index === 7) ? 100 + 'px' : 80 + 'px' }">
          <a @click="itemClick(item.path, index)" :style="{
            'border-bottom':
              currentIndex === index ? '2px solid  #6F8CF3' : '',
            color: currentIndex === index ? '#6F8CF3' : '',
          }" class="nav">{{ item.name }}</a>
        </el-col>
      </el-row>
    </div>
    <div slot="right" :style="searchnow" @keyup.enter="searchs()">
      <el-input placeholder="请输入内容" v-model="search" class="input-with-select" :class="{ active: type }" clearable
        @focus="handleFocus">
        <el-button slot="append" icon="el-icon-search" @click="searchs()"></el-button>
      </el-input>
    </div>
  </nav-bar>
</template>

<script>
import NavBar from 'components/common/navbar/NavBar'

import {
  Decrypt,
  Encrypt
} from '@/util/EncryptionToDecrypt'

// import NavBarItem from 'components/common/navbaritem/NavBarItem'
export default {
  name: 'MainNavBar',
  components: {
    NavBar
    // NavBarItem
  },
  data() {
    return {
      search: '',
      currentIndex: 0,
      type: false,
      navItem: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '疾病',
          path: '/disease'
        },
        {
          name: '视频',
          path: '/mobVideoCenter'
        },
        {
          name: '语音',
          path: '/mobAudioCenter'
        },
        {
          name: '文章',
          path: '/mobArticleCenter'
        },
        {
          name: '问答',
          path: '/mobQuestionCenter'
        },
        {
          name: '医生',
          path: '/mobVideoDoctorMore'
        },
        // {
        //   name: '试药招募',
        //   path: 'http://cancer.irealcare.com/'
        // },
        {
          name: '心理咨询',
          path: 'http://psycho.irealcare.com/'
        },
        {
          name: '安全用药',
          path: '/drug'
        },
        {
          name: '更多服务',
          path: '/moreservice'
        },
        
      ]
    }
  },
  computed: {
    searchnow() {
      // // console.log(this.$route.query.index)
      if (this.$route.query.query) {
        return { width: 656 + 'px' }
      }
      return {}
    }
  },
  watch: {
    $route: function (newVal) {
      this.search = newVal.path === '/mobSearch' ? (Decrypt(this.$route.query.query) ? Decrypt(this.$route.query.query) : '') : ''
      // this.currentIndex = Number(sessionStorage.getItem('nav'))
      console.log(newVal.path)
      if (newVal.path === '/disease') {
        this.currentIndex = 1
        // } else if (newVal.path.indexOf('/video') !== -1) {
      } else if (newVal.path === '/disease/diseasedetail') {
        this.currentIndex = 1
      } else if (newVal.path === '/mobVideoCenterDel') {
        this.currentIndex = 2
      } else if (newVal.path === '/mobVideoCenter') {
        this.currentIndex = 2
      } else if (newVal.path === '/mobAudioCenter') {
        this.currentIndex = 3
      } else if (newVal.path === '/mobVideoCenterAudioDel') {
        this.currentIndex = 3
      } else if (newVal.path === '/mobArticleCenter') {
        this.currentIndex = 4
      } else if (newVal.path === '/mobVideoCenterArticleDel') {
        this.currentIndex = 4
      } else if (newVal.path === '/mobQuestionCenter') {
        this.currentIndex = 5
      } else if (newVal.path.indexOf('/mobQuestionCenterDel') !== -1) {
        this.currentIndex = 5
      } else if (newVal.path === '/mobVideoDoctorMore') {
        this.currentIndex = 6
      } else if (newVal.path === '/mobVideoCenterHome') {
        this.currentIndex = 6
      } else if (newVal.path === '/mobDoctorIndex') {
        this.currentIndex = 6
      } else if (newVal.path === '/drug') {
        this.currentIndex = 9
      } else if (newVal.path === '/drugInfo') {
        this.currentIndex = 9
      } else if (newVal.path === '/drugInsInfo') {
        this.currentIndex = 9
      } else if (newVal.path === '/moreservice') {
        this.currentIndex = 10
      } else {
        this.currentIndex = 0
      }
    },
    immediate: true,
    deep: true
  },
  methods: {
    searchs() {
      if (this.search === '') {
        this.type = true
      } else {
        this.type = false
        this.$router.push({ path: '/mobSearch', query: { query: Encrypt(this.search) } })
      }
    },
    handleFocus() {
      this.type = false
    },
    tohome() {
      this.currentIndex = 0
      this.$router.push({ path: '/' })
    },
    itemClick(e, index) {
      console.log(index, 'index')
      // // sessionStorage.setItem('nav', index)
      if (index === 7 || index === 8) {
        window.open(e, "_blank")
      } else {
        this.$router.push(e)
        this.currentIndex = index
      }
    }
  }
}
</script>

<style scoped>
.mainbar {
  height: 100px;
  position: fixed;
  background-color: #FFFFFF;
  z-index: 999;
}

nav-bar {
  height: 100px;
}

.nav {
  cursor: pointer;
}

img {
  line-height: 100px;
  width: 168px;
  height: 48px;
}

.navbar {
  display: flex;
  padding-right: 40px;
}

.navbar nav-bar-item {
  width: 32px;
  height: 24px;
  line-height: 24px;
}

.active {
  border: 1px solid red;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: black;
}
</style>
