<template>
  <div class="nav-bar" :style="{'width': this.$route.query.query ? 1440 + 'px': 100+ '%'}">
    <div class="left"><slot name="left"></slot></div>
    <div class="center"><slot name="center"></slot></div>
    <div class="right"><slot name="right"></slot></div>
  </div>
</template>

<script>
export default {
  name: 'NavBar'
}
</script>

<style scoped>
.nav-bar{
  display: flex;
  /* width: 1440px; */
  width: 100%;
  height: 100px;
  position: relative;
  text-align: center;
  background-color: #FFFFFF;
  justify-content: center;
}
.left{
  width: 168px;
  margin-right: 200px;
  margin-left: 40px;
  display: flex;
  align-items: center
  /* margin-left: 120px; */
}
.right{
  /* width: 260px; */
  line-height: 100px;
  /* margin-right: 120px; */
}
.center{
  /* width: calc(100%-668px); */
  line-height: 100px;
}
</style>
