<!--
 * @Author: your name
 * @Date: 2021-12-16 12:25:24
 * @LastEditTime: 2021-12-17 10:26:52
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \website\src\App.vue
-->
<template>
  <div id="app">
    <main-nav-bar></main-nav-bar>
    <div class="asd"></div>
    <!-- <keep-alive exclude="Detail"> -->
    <div class="aqa">
      <!-- <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive> -->
      <!-- <router-view v-if="!$route.meta.keepAlive"></router-view> -->
      <router-view></router-view>
    </div>
    <!-- </keep-alive> -->
  </div>
</template>

<script>
import MainNavBar from 'components/content/mainNavbar/MainNavBar'
export default {
  name: 'App',
  components: {
    MainNavBar
  }
  // metaInfo: {
  //   meta: [
  //     { name: 'keywords', content: '科普视频,专家文章,专家音频,名医问答,查疾病,瑞尔安心、瑞尔良医、瑞尔良医app、瑞尔安心官网、瑞尔视景、瑞尔视景科技有限公司、单道动态心电记录仪、7天可穿戴心电监测设备、智能心电监测设备' },
  //     { name: 'description', content: '瑞尔安心在线平台,专家,疾病,科室等详细信息,由专家生产的科普视频,文章,音频等内容,瑞尔安心平台向全民提供“科普视频+语音问答+图文咨询+文章+大数据分析”多位一体的精准健康科普知识，满足大众多样化、多场景应用的健康科普需求。致力于通过互联网技术推动医生共享、知识共享、服务共享、经济共享，放大医生价值和健康科普内容价值，用通俗易懂的方式以及直白语言，制作出大众听得懂、记得住、学得会、用得上的健康科普知识，切实提升公众健康素养，让更多人能够健康地享受美好生活，助力全民健康。研发出多款医疗器械，实现了心脑血管病患者、高危人群得日常疾病筛查和健康管理，并配套了全程专业的健康管理服务，在国内医疗产品行业中具有很高的地位和影响。公司主要从事公司下设医学研发中心、软件开发中心、生产制造中心、营销中心、医学服务中心。' }
  //   ]
  // }
}
</script>

<style>
@import url("~assets/css/common.css");
.asd {
  height: 100px;
  background-color: #ffffff;
  z-index: 10;
}
/* @font-face{
    font-family: 'OPPOSans B';
    src : url('~assets/font/OPPOSans-B.ttf');
}
@font-face{
    font-family: 'OPPOSans M';
    src : url('~assets/font/OPPOSans-M.ttf'); */
/* } */
/* @font-face{
    font-family: 'pang';
    src : url('~assets/font/pang.ttf');
} */
body {
  /* font-family: 'OPPOSans M'; */
  margin: 0 auto;
  /* width: 1440px; */
}
.aqa {
  margin: 0 auto;
  /* left: 50%; */
  /* transform: translateX(-50%); */
}
</style>
