import Vue from 'vue'
import App from './App.vue'
import { Button, Avatar, Pagination, Input, Row, Col, Carousel, Card, CarouselItem, Divider, Form, FormItem, Empty, Loading, Tag } from 'element-ui' // 导入element-ui包
import 'element-ui/lib/theme-chalk/index.css' // 导入相关样式
// import './assets/css/element-variables.css'
import VideoPlayer from 'vue-video-player'
import router from './router'
// import { getMediadetailList } from 'network/getData'
import store from './store'
import Meta from 'vue-meta'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.config.productionTip = false
Vue.use(VideoPlayer)
Vue.use(Button)
Vue.use(Card)
Vue.use(Avatar)
Vue.use(Pagination)
Vue.use(Input)
Vue.use(Row)
Vue.use(Col)
Vue.use(Form)
Vue.use(FormItem)
// Vue.use(Message)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Divider)
Vue.use(Empty)
Vue.use(Loading)
Vue.use(Loading.directive)
Vue.use(Tag)
Vue.use(Meta)
Vue.prototype.$loading = Loading.service

// const head = document.getElementsByTagName('head')// 创建head标签
// const meta1 = document.createElement('meta')// 创建meta标签
// const meta2 = document.createElement('meta')
// meta1.name = 'keywords' // 设置name
// meta2.name = 'description'
// function getMetaContent (index, h, m1, m2) {
//   this.getMediadetailListdata(this.$route.query.audioId, 'audio')
//   // $.ajax({
//   //   type: 'POST',
//   //   url: urlhost+'/news/page',
//   //   data$Type: 'jsonp',
//   //   data: {
//   //     type: index
//   //   },
//   //   success: (res) => {
//   //     let data = JSON.parse(res)
//   //     if(data.status == 0) return;
//   //     let list = data.message[0];
//   //     let key = list.keywords;
//   //     let desc = list.description;
//   //     let title = list.title;
//   //     document.title = title || '设置默认内容';
//   //     m1.content = key || '设置默认内容';
//   //     m2.content = desc || '设置默认内容';
//   //     h[0].appendChild(m1)
//   //     h[0].appendChild(m2)
//   //   }
//   // })
// }

new Vue({
  render: function (h) {
    return h(App)
  },
  router,
  store
}).$mount('#app')
